

//html,
//body {
//  height: 100%;
//  width: 100%;
//  margin: 0;
//  padding: 0;
//}

//body {
//  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//  sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

.app-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  min-width: 1024px;
  overflow: hidden;
}

//.content {
//  display: flex;
//  flex: 1 1 1px;
//  flex-direction: row;
//  align-self: stretch;
//  height: 100%;
//  width: 100%
//}

.map-area {
  display: flex;
  position: fixed;
  min-width: 1024px;
  width: 100%;
  height: calc(100% - 48px);
}

.bg-area {
  display: flex;
  position: fixed;
  min-width: 1024px;
  width: 100%;
  height: calc(100% - 48px);
  //background-color: #ECF3F4;
}

.map-container {
  position: relative;
  //left: 56px;
  //width: calc(100% - 56px);
  width: 100%;
  height: 100%;
}

.map {
  height: 100%;
}

.map-overlay {
  z-index: 1;
  position: absolute;
  padding: 24px;
  min-width: 1024px;
  width: 100%;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-attrib-inner {
  color: rgba(0, 0, 0, 0.75);
}

.mapboxgl-popup-content {
  background-color: #212b3a;
  padding: 4px;
  border-radius: 6px;
  text-align: center;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #212b3a !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #212b3a !important;
}

.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip {
  border-right-color: #212b3a !important;
}

.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip {
  border-left-color: #212b3a !important;
}

.mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl {
  margin: 0 0 10px 16px;
}

.mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-group {
  margin: 0 16px 10px 0px;
}


//
//.map-container {
//  width: 100%;
//  height: 400px;
//}
